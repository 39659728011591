








import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import multiLanguageContentInput from "@/utils/multiLanguageContentInput";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Privacy Policy",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                privacyNoticeContent: multiLanguageContentInput(),
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call("setting", "update", undefined, {
                    data,
                  });
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Privacy Policy saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    const data = await coreApiClient.call("setting", "get");
    this.dataContainer.value = data;
  },
});
